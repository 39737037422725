import React from "react";
import { Link } from "gatsby";
import HomeLanding from "../HeroLanding";
import { gray4 } from "../styles/Colors";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import HeroMarketPlace from "../brands/HeroMarketplace";
import ShopsLanding from "../brands/ShopsLanding";

const BrandsShopsStatic = () => {
  return (
    <>
      <HomeLanding
        section="SHOPS FOR BRANDS"
        blurImage={true}
        animationComponent={<ShopsLanding />}
        link={{
          label: "Get Started",
          attrs: { to: "/contact/?smopt=brand", state: { from: "brand" }, as: Link }
        }}
        bottomMargins={"-80px"}
        theme={{
          blurHeightLarge: 25,
          titleWidth: "100%",
          topPadding: "200px",
          topLargePadding: "200px",
          bottom: gray4,
          bottomPadding: "272px",
          imageMarginTop: 0,
          bottomSize: "172px",
          bottomSizeBottomMedia: "118px"
        }}
      >
        <OutlinedText fill="transparent" component={Headline80} as="span">
          Supercharge
        </OutlinedText>
        Your Sales
      </HomeLanding>

      <HeroMarketPlace />
    </>
  );
};

export default BrandsShopsStatic;
