import React from "react";
import styled from "styled-components";
import { gray4 } from "../styles/Colors";
import PagePadding from "../styles/PagePadding";
import TextHighlight from "../styles/TextHighlight";
import Image from "../Image";
import LinesFromRight from "../LinesFromRight";
import { graphql, useStaticQuery } from "gatsby";
import { mediaMedium } from "../styles/variables";
import { Headline80 } from "../styles/Headings";
import Container from "../styles/Container";
import { Body24 } from "../styles/BodyTypes";

const Display = styled.div`
  text-align: center;
  margin-top: -60px;

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  @media (${mediaMedium}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -52px;

    .gatsby-image-wrapper {
      flex: 1 1 100%;
      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    .gatsby-image-wrapper {
      width: calc(100% + 160px);
      max-width: none !important;
      max-height: none !important;
      transform: translateX(80px);
    }
  }
`;

const Decoration = styled.div`
  display: none;
  pointer-events: none;

  @media (${mediaMedium}) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;
    transform: translate(65%, 93%);
    padding-bottom: 0;
  }
`;

const Text = styled.p`
  &:first-of-type {
    margin-top: 56px;
  }

  @media (${mediaMedium}) {
    &:first-of-type {
      margin-top: 48px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 24px;
  padding: 0;
  position: relative;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }

  @media (${mediaMedium}) {
    grid-template-columns: 40% 50%;
  }
`;

const Wrapper = styled.div`
  background-color: ${gray4};
  position: relative;
  overflow: hidden;
  padding-bottom: 38px;

  @media (${mediaMedium}) {
    padding-bottom: 120px;
  }
`;

const Section = ({ image }) => {
  return (
    <Wrapper>
      <Container>
        <PagePadding>
          <Headline80>go beyond the marketplace</Headline80>
        </PagePadding>

        <Grid>
          <Decoration>
            <LinesFromRight />
          </Decoration>

          <PagePadding>
            <Body24 as="div">
              <Text>
                We believe the little guy deserves the frontpage story just as
                much as the household name.
              </Text>

              <p>
                With Stack, not only do your products get featured across
                hundreds of publisher-branded shops in just a few clicks, but
                you’ll have the opportunity to be featured in articles,
                newsletters, and social posts across the web{" "}
                <TextHighlight theme={{ background: gray4 }}>
                  at no cost to you.
                </TextHighlight>
              </p>
            </Body24>
          </PagePadding>

          <Display>
            <Image fluid={image.file.childImageSharp.fluid} />
          </Display>
        </Grid>
      </Container>
    </Wrapper>
  );
};

const HeroMarketPlace = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "62-marketplace-circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, maxHeight: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <Section image={data} />;
};

export default HeroMarketPlace;
