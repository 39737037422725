import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/BrandsShopsStatic";

const DeferSection = loadable(() =>
  import("../../components/sections/BrandsShopsDefer")
);

const SelfServeShops = () => (
  <Layout>
    <Seo
      title="Marketplace Platform for Merchants"
      description="Instantly get listed across thousands of publisher marketplaces to sell more faster. Target customers with our network of publishers."
    />

    <LazyLoadSection static={<StaticSection />}>
      <DeferSection />
    </LazyLoadSection>
  </Layout>
);

export default SelfServeShops;
